<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> လမ်းညွှန် ထည့်ရန်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputType" class="col-sm-2 col-form-label">လမ်းညွှန် အမျိုးအစား <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select v-model="directoryRequest.type" @blur="validationRule()"
										class="form-control" id="inputType">
										<option value="">ရွေးချယ်မယ်</option>
										<option value="education">ပညာရေးဆိုင်ရာ</option>
										<option value="finance">ငွေကြေးဆိုင်ရာ</option>
										<option value="hotel">တည်းခိုမှုဆိုင်ရာ</option>
									</select>
									<small class="text-danger">{{validationData.type}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputTitle" class="col-sm-2 col-form-label">လမ်းညွှန် ခေါင်းစီး <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.title" @blur="validationRule()"
										class="form-control" id="inputTitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputTitleEn" class="col-sm-2 col-form-label">လမ်းညွှန် ခေါင်းစီး (EN)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.title_en" @blur="validationRule()"
										class="form-control" id="inputTitleEn">
									<small class="text-danger">{{validationData.title_en}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">ဖုန်းနံပါတ်<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label">လိပ်စာ <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress">
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">ဓာတ်ပုံ </label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="directoryRequest.image" v-bind:src="directoryRequest.image" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label">ဓာတ်ပုံ အဟောင်း </label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="my-modal" hide-backdrop hide-footer>
			<template v-slot:modal-name>
				Alert
			</template>
			<div class="d-block text-center">
				<p>You must fill out the required fields before making other changes.</p>
			</div>
		</b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'directoryEdit',
		metaInfo: {
            title: "directory Edit",
            titleTemplate: "%s ← SC Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentFile: "",
				currentImage: "",
				filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    name: ""
				},
				directoryRequest: {
					id: "",
					type: "",
					title: "",
					title_en: "",
					phone: "",
					address: "",
					image: ""
				},
				validationData: {
					type: "",
					title: "",
					title_en: "",
					phone: "",
					address: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				directoryUpdateAction: 'directoryUpdateAction',
				directoryFieldsAction: 'directoryFieldsAction'
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.directoryRequest.image = e.target.result
				};
			},
			async getFields(){
				let option = {
					fields: {id: this.directoryRequest.id}
				}
				await this.directoryFieldsAction({
					...option
				}).then(res => {
					this.directoryRequest.type = res.data.type
					this.directoryRequest.title = res.data.title
					this.directoryRequest.title_en = res.data.title_en
					this.directoryRequest.phone = res.data.phone
					this.directoryRequest.address = res.data.address
					this.currentImage = res.data.image
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.directoryRequest
				await this.directoryUpdateAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'directory-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, title: this.filter.title} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.directoryRequest.type == "") {
					this.validationData.type = "The type field is required."
					isValidation.push(true)
				} else {
					this.validationData.type = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.title_en == "") {
					this.validationData.title_en = "The title (EN) field is required."
					isValidation.push(true)
				} else {
					this.validationData.title_en = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.address == "") {
					this.validationData.address = "The address field is required."
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
			this.directoryRequest.id = this.$route.query.id
			this.filter.title = this.$route.query.title
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>